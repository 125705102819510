.assistant-image {
    width: 80px; /* 根据需要调整大小 */
    height: 80px; /* 根据需要调整大小 */
    z-index: 1000; /* 确保图像位于页面顶部 */
    cursor: grab; /* 鼠标悬停时显示抓手 */
    user-select: none; /* 禁止选择图像 */
}

.assistant-bubble {
    position: absolute;
    padding: 10px 15px;
    background-color: #ffd700; /* 醒目的黄色背景 */
    color: #333;
    font-size: 16px;
    font-weight: bold;
    border-radius: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transform: translateY(0);
    animation: bounce 1s infinite; /* 弹跳动画 */
    transition: background-color 0.3s ease, transform 0.2s ease;
}

/* 鼠标悬停效果 */
.assistant-bubble:hover {
    background-color: #ffa500; /* 更加醒目的橙色 */
    transform: scale(1.05); /* 放大效果 */
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px); /* 弹起 */
    }
    60% {
        transform: translateY(-5px); /* 轻微下落 */
    }
}
