/* Category selector container */
.category-selector {
  position: relative;
  background-color: rgba(0, 0, 0, 0.4);
  margin-top: 10px;
  padding: 1%;
  height: 100%;
  max-height: 100%;
  border-radius: 8px;
}

.selected-label {
  margin-left: 10px;
  margin-bottom: 16px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
}

/* Category tab button styling */
.category-tab-button {
  width: auto; /* 设置固定宽度 */
  transition: all 0.3s ease;
  font-size: 16px;
  background-color: inherit;
  color: rgba(255, 255, 255, 0.6);
  border: 0px solid rgba(255, 255, 255, 0.3);
  text-align: center;
}

/* Category tabs row styling */
.category-tabs-row {
  margin-bottom: 4px;
  display: flex;
  justify-content: flex-start; /* 确保从左对齐 */
  flex-wrap: wrap; /* 允许换行 */
}



.category-tab-button.active-tab {
  color: rgba(255, 255, 255, 1);
  position: relative;
}

.category-tab-button.active-tab::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 2px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 1px;
}


.category-tab-button:hover {
  background-color: inherit !important;
}


/* Category row styling */
.category-row {
  margin-bottom: 8px;
}

/* Category button styling */
.category-button {
  margin-right: 8px;
  margin-bottom: 8px;
  transition: all 0.3s ease;
  font-size: 16px;
  background-color: rgb(35, 35, 35);
  color: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.6);
}

.category-button:hover {
  border: 0px solid rgba(255, 255, 255, 0.5);
  background-color: inherit !important;
}

.category-button:focus {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

/* Styling for selected buttons */
.category-button-selected {
  background-color: rgb(35, 35, 35);
  font-size: 16px;
  color: white;
  margin-right: 8px;
  margin-bottom: 8px;
}

.category-button-selected:hover {
  background-color: inherit !important;
  font-size: 16px;
  color: white;
  margin-right: 8px;
  margin-bottom: 8px;
}

/* Container styling */
.category-container {
  max-height: calc(100vh - 320px);
  overflow-y: auto;
  margin-top: 20px;
  padding: 4px;
  border-radius: 8px;
}

/* Custom scrollbar styles */
.category-container::-webkit-scrollbar {
  width: 8px;
}

.category-container::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.category-container::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
}

.category-container::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.8);
}

/* Selected category row styling */
.selected-category-row {
  margin-top: 16px;
  margin-bottom: 16px;
}

.category-background {
  background-color: #242628;
  padding: 10px;
  border-radius: 16px;
}