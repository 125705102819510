/* Assistant/VideoModal.css */

.demo-video-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.demo-video-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 50%;
    width: 100%;
    position: relative; /* 为绝对定位的关闭按钮提供参考 */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.demo-video-close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    color: black; /* 关闭按钮的颜色 */
    cursor: pointer;
    transition: color 0.3s;
}

.demo-video-close:hover {
    color: red; /* 鼠标悬停时的颜色变化 */
}

video {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}


.video-tabs {
    display: flex;
    margin-bottom: 10px;
}

.video-tabs button {
    margin-right: 10px;
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    background: #f0f0f0;
    border-radius: 5px;
}

.video-tabs button.active {
    background: #007bff;
    color: white;
}
