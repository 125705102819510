.watermark {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 20px;
    color: white;
    font-style: italic;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Optional: adds a shadow for better visibility */
    pointer-events: none; /* Ensures the watermark doesn't interfere with other UI elements */
  }
  