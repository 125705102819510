.member-list {
    background-color: #353535;
    width: 100%;
    border-radius: 10px;
    padding: 25px 30px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
  }
  
  .member-list h2 {
    font-size: 1.8rem;
    color: #ffffff;
    border-bottom: 2px solid #555;
    padding-bottom: 10px;
  }
  
  .member-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
    border-bottom: 1px solid #4a4a4a;
    color: #e0e0e0;
    transition: background-color 0.3s ease;
  }
  
  .member-item:hover {
    background-color: #444;
  }
  
  .member-details {
    display: flex;
    flex-direction: row; /* 水平排列 */
    gap: 20px; /* 每个字段之间的间距 */
  }
  
  .member-details span {
    white-space: nowrap; /* 防止换行 */
  }
  
  .member-actions {
    display: flex;
    gap: 10px; /* 按钮之间的间距 */
  }
  
  button {
    padding: 8px 12px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: #424242; /* 默认灰色调 */
    color: #ffffff; /* 白色文字 */
  }
  
  .edit-member-button {
    background-color: #4a4a4a; /* 较浅的灰色 */
  }
  
  .delete-member-button {
    background-color: #5a5a5a; /* 深灰色 */
  }
  
  .delete-member-button.disabled {
    background-color: #6c6c6c; /* 更浅的灰色，表示禁用状态 */
    cursor: not-allowed;
  }
  
  .save-member-button {
    background-color: #3c3c3c; /* 深灰色，适合保存操作 */
  }
  
  .cancel-member-button {
    background-color: #5c5c5c; /* 中灰色，适合取消操作 */
  }
  
  .add-member-button {
    margin-top: 15px;
    padding: 10px 15px;
    background-color: #4c4c4c; /* 中性灰 */
    color: #ffffff;
  }
  
  button:hover {
    background-color: #5a5a5a; /* 悬停时稍微变浅 */
  }
  