.works-square {
    padding: 20px;
    width: 100%;
    margin: 0 auto;
    border-radius: 8px;
}

.masonry-grid {
    display: flex;
    margin-left: -10px; /* 调整列间距 */
    width: auto;
}

.masonry-grid-column {
    padding-left: 10px; /* 调整列间距 */
    background-clip: padding-box;
}

.masonry-grid-column > .image-unit {
    margin-bottom: 10px;
}

.loading-text, .no-works-text, .all-loaded-text {
    width: 100%;
    text-align: center;
    font-size: 1.2em;
    color: #555;
    margin: 20px 0;
}

.loading-text {
    color: #888;
}

.no-works-text {
    color: #999;
}

.all-loaded-text {
    color: #4CAF50;
    font-weight: bold;
    position: sticky;
    bottom: 20px;
}

.bottom-sensor {
    width: 100%;
    height: 1px;
}

.masonry-grid {
    column-gap: 10px;
  }
  .masonry-grid-column {
    row-gap: 10px;
  }
  