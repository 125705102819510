.feedback-page {
    padding: 20px;
    background-color: rgba(35,35,35,0.7);
    color: azure;
    height: 100%;
}

.form-group {
    margin-bottom: 20px;
}

.feedback-textarea {
    width: 400px;
    height: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: black;
}
