.full-screen-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    overflow-y: auto; /* 启用滚动 */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.9); /* 半透明背景 */
    padding: 20px; /* 避免内容贴边 */
}

.full-screen-container::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
}

.full-screen-content {
    background: #1c1c1c;
    color: #ccc;
    border-radius: 10px;
    width: 90%;
    max-width: 40%; /* 限制最大宽度 */
    padding: 40px 5%; /* 内边距 */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* 调整阴影 */
    overflow-y: auto; /* 启用滚动 */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    max-height: 90vh; /* 限制高度，避免超出屏幕 */
    user-select: none; /* 禁止文字选中 */
    pointer-events: auto; /* 确保按钮等交互仍可用 */
}

.full-screen-content::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
}
