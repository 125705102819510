.input-section {
  width: 100%;
  padding: 20px;
  background-color: #242628;
  margin-bottom: 20px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border: 0px solid rgba(255, 255, 255, 0.2);
}

.section-title {
  color: #fff;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: left;
}

.input-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
}

.label {
  width: 100px;
  color: #fff;
  font-weight: 600;
  text-align: left;
}

.member-exclusive {
  color: red;
  font-size: 12px;
  margin-left: 5px;
}

.image-count-buttons {
  display: flex;
  gap: 10px;
  width: 100%;
}

.seed-input {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  width: 100%;
}

.seed-input:hover,
.seed-input:focus {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
}

.seed-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.control-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.control-buttons .ant-btn {
  font-size: 16px;
  border-radius: 12px;
  padding: 10px 20px;
  background: linear-gradient(135deg, #ffafbd, #ffc3a0, #2196F3, #21CBF3);
  color: white;
  border: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 1.5s ease;
}

.control-buttons .ant-btn:hover {
  background: linear-gradient(135deg, #2196F3, #21CBF3, #ffafbd, #ffc3a0) !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: rgb(35, 35, 35);
  color: #fff;
  font-weight: 600;
  border: 0;
}

.ant-collapse-content {
  background-color: rgb(35, 35, 35);
}

.ant-collapse-content-box {
  background-color: rgb(35, 35, 35);
  padding: 10px;
  color: #fff;
}

.image-count-buttons button,
.checkbox-hd {
  margin-right: 10px;
}