/* src/assets/styles/Realpaint.css */

.button-hover {
    background: black;
}

.custom-sider {
    width: 100%;
}

.image-info {
    width: 100%;
    padding: 10px;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: red;
    cursor: pointer;
}

.selected-image {
    max-width: 100%; /* 限制最大宽度 */
    max-height: 100%; /* 限制最大高度 */
    object-fit: contain; /* 保持图片比例 */
    margin: auto; /* 确保图片在容器中居中 */
}

  .upload-prompt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: white;
    cursor: pointer;
  }

.canvas-container {
position: relative;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
}