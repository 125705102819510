.footer__container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    color: #fff;
    padding-bottom: 5%;
    border-top: 1px solid #292929;
    flex-wrap: wrap;
}

.footer__column {
    flex: 1;
    margin: 0 20px;
}

.footer__column-title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 5px;
}

.footer__logo img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.footer__about-list {
    list-style: none;
    padding: 0;
    margin-top: 15px;
}

.footer__about-item {
    margin-bottom: 8px;
}

.footer__about-link {
    color: #fff;
    text-decoration: none;
}

.footer__about-link:hover {
    cursor: pointer;
}


.footer__tech-stack-text {
    margin: 0;
    margin-top: 15px;
}

/* 备案信息样式 */
.footer__record-info {
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

.footer__record-text {
    font-size: 14px;
    color: #bbb;
    margin: 0;
    margin-top: 10px;
}


.footer__record-link {
    color: #4e4e4e;
    text-decoration: none;
    margin-right: 15px;
}

.footer__record-link:hover {
    text-decoration: underline;
}
.email-link {
    color: white;
    text-decoration: none; /* 去掉下划线 */
}

.email-link:hover {
    text-decoration: underline; /* 鼠标悬停时显示下划线 */
}
