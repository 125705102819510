.custom-textarea {
    margin: 2% 6% 4% 6%;
    position: relative;
}

.custom-textarea__input,
.custom-textarea__input:hover,
.custom-textarea__input:focus {
    background-color: #242628;
    border-color: #3a3b3c;
    color: aliceblue;
    padding-top: 2%;
    padding-bottom: 30px;
    border-radius: 16px;
}

.custom-textarea__char-count {
    position: absolute;
    background-color: #3a3b3c;
    padding: 0.5% 1%;
    border-radius: 6px;
    border: 1px solid #4a4b4c;
    top: 0%;
    right: 12px;
    transform: translateY(-50%);
    color: aliceblue;
    font-size: 14px;
}

.custom-textarea__input::placeholder {
    color: white;
    opacity: 0.8;
}

.custom-textarea__options {
    display: flex;
    align-items: center;
    position: absolute;
    left: 12px;
    bottom: 12px;
}

.custom-textarea__dropdown-button {
    background-color: #3a3b3c;
    color: aliceblue;
    border: none;
    padding: 4px 10px;
    cursor: pointer;
    margin-right: 10px;
}

.custom-textarea__dropdown-button:hover {
    background-color: #4a4b4c;
}

.custom-textarea__hd-checkbox {
    display: flex;
    align-items: center;
    color: aliceblue;
    background-color: #3a3b3c;
    border: none;
    padding: 4px 10px;
    border-radius: 6px;
}

.custom-textarea__hd-checkbox .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
}

.custom-textarea__hd-checkbox .ant-checkbox-inner {
    background-color: #3a3b3c;
    border: 1px solid white;
}

.custom-textarea__hd-checkbox .ant-checkbox-inner:hover {
    background-color: #4a4b4c;
}

.custom-textarea__hd-checkbox .ant-checkbox + span {
    margin-left: 8px;
}

.custom-textarea__generate-button {
    position: absolute;
    right: 12px;
    bottom: 12px;
    background-color: #3369FF;
    color: aliceblue;
    font-size: 16px;
    font-weight: bold;
    border: none;
    padding: 6px 24px;
    cursor: pointer;
    border-radius: 4px;
}

.custom-textarea__generate-button:hover {
    background-color: #4a4b4c;
}
    