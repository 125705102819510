.home-container {
    padding: 30px;
    height: 100vh;
    margin: 0 auto;
    background-color: #1c1c1c;
    overflow: auto; /* 允许滚动 */
}

/* 隐藏滚动条的样式 */
.home-container::-webkit-scrollbar {
    width: 0; /* 隐藏垂直滚动条 */
}

.home-container::-webkit-scrollbar-thumb {
    background: transparent; /* 隐藏滚动条的滑块 */
}

.home-section {
    margin-bottom: 40px; /* 每个组件之间的间隔 */
    border-radius: 16px;
    position: relative;
}

.home-section home-section-Tools{
    margin-bottom: 40px; /* 每个组件之间的间隔 */
    border-radius: 16px;
    position: relative;
}

.home-section-title {
    position: absolute;
    top: -30px; /* 将标题放在左上角 */
    left: 20px;
    padding: 5px 10px;
    font-size: 1.2em;
    font-weight: bold;
    color: #ffffff;
}
