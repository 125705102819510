.doodle-selector {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  border: 0px solid;
  background-color: rgba(0, 0, 0, 0.4);
}

.doodle-button {
  width: 100px;
  height: 60px;
  font-size: 16px;
  border-radius: 4px;
  color: #ddd;
  background-color: rgba(0, 0, 0, 0.4);
}

.doodle-button.active {
  background-color: #1890ff;
  color: white;
}

.doodle-button:first-child {
  margin-right: 10px;
}
