/* CSS */

.home-display-image-container,
.home-display-video-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-display-image,
.home-display-video {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Ensures the media fits within the container without distortion */
    z-index: 1;
}


.home-display-item {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
    position: relative;
}

.home-display-content {
    min-width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}


.home-display-blur-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    filter: blur(20px);
    z-index: 0;
}

.home-display-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 8px;
}

.home-display-indicator {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: background-color 0.3s;
}

.home-display-indicator.active {
    background-color: rgba(255, 255, 255, 1);
}

.home-display-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #fff;
    font-size: 2rem;
    cursor: pointer;
    z-index: 2;
}

.home-display-nav.left {
    left: 10px;
}

.home-display-nav.right {
    right: 10px;
}
