/* src/assets/styles/History.css */
.history-image-gallery-container {
    position: relative;
    background-color: #1c1c1c;
    width: 100%;
    height: 100vh;
    margin-bottom: 20px;
    overflow-y: auto;
  }
  
  .history-top-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .history-bottom-pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .ant-pagination-item a {
    color: white !important;
  }
  
  .ant-pagination-item-active a {
    color: black !important;
  }
  
  .ant-pagination-prev, 
  .ant-pagination-next {
    color: white !important;
  }
  
  /* Custom scrollbar styles */
  .history-image-gallery-container::-webkit-scrollbar {
    width: 8px; /* 滑动条的宽度 */
  }
  
  .history-image-gallery-container::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2); /* 滑动条轨道的背景颜色 */
    border-radius: 8px; /* 圆角滑动条轨道 */
  }
  
  .history-image-gallery-container::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5); /* 滑动条滑块的颜色 */
    border-radius: 8px; /* 圆角滑块 */
  }
  
  .history-image-gallery-container::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.8); /* 滑块在悬停时的颜色变化 */
  }
  
  .history-loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }
  
  .history-pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px; /* 固定高度以确保分页容器的空间 */
  }
  
  .history-pagination-container .ant-pagination {
    color: white; /* 设置字体颜色为白色 */
  }
  
  .history-pagination-container .ant-pagination-item a {
    color: white; /* 设置分页按钮的文字颜色为白色 */
  }
  
  .history-pagination-container .ant-pagination-item-active a {
    color: black; /* 设置当前活动页码的文字颜色为白色 */
  }
  
  .history-pagination-container .ant-pagination-prev, 
  .history-pagination-container .ant-pagination-next {
    color: white; /* 设置上一页和下一页按钮的文字颜色为白色 */
  }
  
  .history-batch-container {
    background-color: rgba(45, 45, 45, 0.85); /* 优化背景色 */
    border-radius: 16px; /* 增加圆角 */
    padding: 15px 40px;
    margin: 10px 5%;
    position: relative;
}

  
  .history-batch-date {
    font-size: 14px; /* 调整字体大小 */
    font-weight: bold;
    color: #fff; /* 改为白色以匹配深色背景 */
    text-align: left; /* 左对齐日期 */
    letter-spacing: 0.5px; /* 增加字母间距以提升可读性 */
  }
  
  .history-image-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* 增加图片之间的间距 */
    margin-top: 15px; /* 增加顶部间距 */
  }
  
  .history-image-container {
    position: relative;
    box-sizing: border-box;
    width: 20%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px; 
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* 添加平滑过渡效果 */
  }
  
  .history-image-container:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25); /* 增加阴影效果 */
  }
  
  .history-image-container.selected {
    border: 2px solid #1890ff;
  }
  
  .history-image-container img {
    width: 100%; /* 固定图片宽度 */
    height: 200px; /* 固定图片高度，可以根据需求调整 */
    object-fit: cover; /* 保持图片比例并裁剪 */
    display: block;
    border-radius: 8px; /* 可选：增加圆角 */
    transition: transform 0.3s ease;
  }
  
  
  .history-image-container:hover {
    transform: scale(1.05);
  }
  
  .history-seed-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6); /* 优化背景色 */
    color: #fff;
    text-align: center;
    padding: 5px;
    font-size: 12px;
    transform: translateY(100%);
    transition: transform 0.3s ease;
    border-top-left-radius: 8px; /* 增加圆角 */
    border-top-right-radius: 8px;
  }
  
  .history-image-container:hover .history-seed-overlay {
    transform: translateY(0);
  }
  
  /* 中间显示预估时间 */
  .history-eta-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 6px 30px; /* 调整padding以适应较小的字体 */
    font-size: 12px;  /* 调整字体大小 */
    text-align: center;
    border-radius: 8px;
    pointer-events: none; /* 禁止鼠标事件 */
  }
  
  
  /* 手机端适配 */
  @media (max-width: 768px) {
    .history-image-gallery {
      gap: 5px; /* 调整图片间距 */
    }
  
    .history-image-container {
      width: calc(50% - 5px); /* 每行显示两个图片，间距调整 */
    }
  }
  
  /* 小屏幕适配 */
  @media (max-width: 480px) {
    .history-image-container {
      width: calc(100% - 10px); /* 每行显示一个图片，间距调整 */
    }
  }
  
  .favorite-button {
    margin-left: auto; /* 确保收藏按钮在行的最右侧 */
    cursor: pointer;
  }
  
  .favorite-button:hover {
    cursor: pointer;
  }
  