/* src/assets/styles/ImageGallery.css */
.image-gallery-container {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

/* Custom scrollbar styles */
.image-gallery-container::-webkit-scrollbar {
  width: 8px; /* 滑动条的宽度 */
}

.image-gallery-container::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2); /* 滑动条轨道的背景颜色 */
  border-radius: 8px; /* 圆角滑动条轨道 */
}

.image-gallery-container::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5); /* 滑动条滑块的颜色 */
  border-radius: 8px; /* 圆角滑块 */
}

.image-gallery-container::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.8); /* 滑块在悬停时的颜色变化 */
}

.image-container.placeholder {
  background-color: #333333; /* 灰色背景板 */
  width: calc(25% - 10px);
  height: 150px;
  border: 0px solid;
  font-size: 12px;
  color: antiquewhite;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-style: italic;
}

.loading-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px; /* 固定高度以确保分页容器的空间 */
}

.pagination-container .ant-pagination {
  color: white; /* 设置字体颜色为白色 */
}

.pagination-container .ant-pagination-item a {
  color: white; /* 设置分页按钮的文字颜色为白色 */
}

.pagination-container .ant-pagination-item-active a {
  color: black; /* 设置当前活动页码的文字颜色为黑色 */
}

.pagination-container .ant-pagination-prev, 
.pagination-container .ant-pagination-next {
  color: white; /* 设置上一页和下一页按钮的文字颜色为白色 */
}

.batch-container {
  position: relative;
  padding: 10px; /* 统一上下间距 */
  border-radius: 10px; /* 圆角保持一致 */
}

.batch-container.blurred .image-gallery {
  filter: blur(5px);
}

.batch-date {
  font-size: 18px;
  font-weight: bold;
  color: #797979;
  text-align: left; /* 左对齐日期 */
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 5px; /* 图片之间的间距 */
}

.image-container {
  position: relative;
  width: calc(25% - 10px);
  max-height: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.image-container.selected img{
  border: 2px solid #1890ff;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-container:hover {
  transform: scale(1.05);
}

.processing-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* 半透明背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: antiquewhite;
  animation: breathing 2s infinite ease-in-out;
}

@keyframes breathing {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
