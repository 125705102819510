.image-display-thumbnail {
  cursor: pointer;
  max-width: 100%;
  max-height: 100%;
  border: 0px;
}

.image-display-modal .ant-modal-content {
  background-color: transparent;
  border-radius: 16px;
  border: 0px;
}

.image-display-modal .ant-modal-close {
  color: white;
  font-size: 24px;
  right: 16px;
  top: 16px;
}

.image-display-container {
  position: relative;
  margin: 0 6%;
  height: 65vh;
  padding-bottom: 2%;
  background-color: #242628;
  border-radius: 16px;
}

.image-display-screen {
  height: 60vh;
  display: flex;
  justify-content: center;
  padding: 4% 5%;
  border-radius: 8px;
}

.image-display-screen img{
  max-height: 60vh;
  border-radius: 8px;
}

.modal-content {
  position: relative;
}

.like-container {
  position: absolute;
  top: 20px;
  right: 10px;
  align-items: center;
  gap: 5px;
  opacity: 0.8;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
}

.like-button {
  font-size: 14px;
  color: #333;
}

.close-button {
  font-size: 14px;
  color: #333;
}

.thumbnail-container {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: -2%;
  overflow-x: auto;
}

.thumbnail-image {
  width: auto;
  height: 45px;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 8px;
  transition: border-color 0.2s ease-in-out;
}

.thumbnail-image:hover {
  border-color: #1890ff;
  border-radius: 8px;
}

.thumbnail-selected {
  border-color: #ffffff;
  border-radius: 8px;
}

.placeholder {
  height: 60vh;
  display: flex;
  justify-content: center;
  padding: 4% 5%;
  border-radius: 8px;
}

