
.label-style {
  font-size: 14px;
  margin-left: 10px;
  color: #ffffff;
  font-weight: bold;
}



.breadcrumb__path {
  margin-left: 6%;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 2px;
}

.down-section {
  flex: 1;
}

.breadcrumb__down__section {
  flex: 1;
  overflow: auto;
  scrollbar-width: none; /* 针对 Firefox */
  -ms-overflow-style: none; /* 针对 Internet Explorer 和 Edge */
}

/* 针对 Webkit 浏览器 */
.breadcrumb__down__section::-webkit-scrollbar {
  display: none;
}
