/* MaskEditor.css */

.toolbar {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
    display: flex;
    align-items: center;
  }
  
  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    margin-right: 5px;
  }
  
  .icon-button svg {
    width: 24px;
    height: 24px;
    fill: none;
    stroke: #fff;
  }
  
  .slider {
    margin-right: 5px;
  }
  
  .slider-label {
    color: white;
  }
  