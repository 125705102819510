.advanced-settings {
    width: 100%;
    display: flex;
  }
  
  .advanced-settings__toggle-button:hover {
    color: white !important;
    background-color: transparent !important;
  }

  
  .advanced-settings__toggle-button {
    color: white !important;
    width: 100%;
    margin-left: 1%;
    text-align: left;
    font-size: 18px; /* 调整字体大小 */
    background-color: transparent !important;
  }
  
  .advanced-settings__toggle-button-label {
    text-align: left;
  }

  .advanced-settings__controls {
    padding: 0 16px;
  }
  
  
  .advanced-settings__slider-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: white;
  }
  
  .advanced-settings__label {
    flex: 1; /* 让标签占用一部分宽度 */
    margin-right: 8px;
  }
  
  .advanced-settings__slider {
    flex: 2; /* 让 Slider 占用两倍宽度 */
    margin-right: 8px;
  }
  
  .advanced-settings__input-number {
    width: 80px;
    background-color: #3a3b3c;
    border: 0 solid;
    color: white !important;
  }
  