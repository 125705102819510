/* header-styles.css */
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #242628;
    color: white;
    height: 5vh;
    min-height: 50px;
    padding: 10px 20px;
    border-bottom: 1px solid #3a3b3c;
    position: relative;
}

.header-container-title {
    margin: 0;
    font-size: 24px;
    color: #ffffff;
    font-style: italic;
}

.header-container-title-clickable {
    cursor: pointer;
}

.header-container-right {
    display: flex;
    align-items: center;
    gap: 20px; /* Space between notification icon and auth controls */
}

.header-button {
    cursor: pointer;
    color: #007bff;
    font-size: 14px;
    background-color: transparent;
    border: none;
    padding: 5px 10px;
    transition: color 0.3s;
    border-radius: 10px;
}

.header-button:hover {
    color: #0056b3;
}

.header-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 10px;
}

.header-avatar-container {
    position: relative;
    display: inline-block;
}

.header-user-panel {
    position: absolute;
    top: 60px;
    right: 10px;
    background: rgb(44, 44, 44);
    border: 0px solid #ccc;
    padding: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    color: rgb(255, 255, 255);
    border-radius: 8px;
    width: 250px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.header-user-panel-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #252525;
}

.header-user-panel-label {
    font-weight: bold;
}

.header-user-panel-value {
    text-align: right;
    flex: 1;
}

.header-user-panel-button {
    margin-top: 10px;
    cursor: pointer;
    color: #ffffff; /* Button text in white */
    font-size: 14px;
    border: none;
    padding: 8px 10px;
    transition: background-color 0.3s ease;
    border-radius: 5px;
    width: 100%;
    text-align: center;
}

.header-user-panel-button:hover {
    background-color: #999999; /* Button color on hover */
}

/* Notification Icon Styles */
.header-notification-icon {
    position: relative;
    cursor: pointer;
    color: #ffffff;
}

.header-notification-icon-dot {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
}

@keyframes scroll-title {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

/* Notification Dropdown Styles */
.header-notification-dropdown {
    position: absolute;
    top: 60px; /* Adjust based on header height */
    right: 60px; /* Adjust based on header-right spacing */
    width: 300px;
    max-height: 400px;
    background-color: rgb(44, 44, 44);
    border: 1px solid #3a3b3c;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    padding: 10px;
}

.header-notification-item {
    padding: 10px;
    border-bottom: 1px solid #252525;
    cursor: pointer;
    transition: background-color 0.3s;
}

.header-notification-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
  }
  
.header-notification-icon-dot {
width: 10px;
height: 10px;
background-color: red;
border-radius: 50%;
margin-left: 10px;
}

  
.header-notification-item:last-child {
    border-bottom: none;
}

.header-notification-item:hover {
    background-color: #3a3b3c;
}

.header-notification-title {
    font-weight: bold;
    color: #ffffff;
}

.header-notification-date {
    font-size: 12px;
    color: #bbbbbb;
    margin-top: 5px;
}

/* Modal Styles */
.header-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.header-modal-content {
    background-color: rgb(44, 44, 44);
    color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
}

.header-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-modal-close-button {
    background: transparent;
    border: none;
    color: #ffffff;
    font-size: 24px;
    cursor: pointer;
}

.header-modal-body {
    margin-top: 20px;
}
