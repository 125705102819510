.flex-container {
    display: flex;
    flex-direction: row;
    height: 100%;
  }
  
.l1-left-container,
.l1-middle-container,
.l1-right-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.l1-left-container {
  flex: 9;
  border-right: 1px solid #3a3b3c;
}

.l1-middle-container {
  flex: 28;
  border-right: 1px solid #3a3b3c;
}

.l1-right-container {
  flex: 9;
}


.l2-left-container,
.l2-right-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.l2-left-container {
  flex: 27;
  border-right: 1px solid #3a3b3c;
}


.l2-right-container {
  flex: 6;
}