.image-unit {
    width: 300px;
    margin: 10px;
    position: relative;
    overflow: hidden;
  }
  
  .image-unit__image-container {
    cursor: pointer;
  }
  
  .image-unit__image {
    width: 100%;
    height: auto;
    border-radius: 16px;
    display: block;
    object-fit: cover;
  }
  
  .image-unit__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 10px;
    box-sizing: border-box;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  
  .image-unit__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
  }
  
  .image-unit__avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .image-unit__username,
  .image-unit__favorites {
    color: #ffffff;
  }
  
  .image-unit__prompt {
    font-size: 12px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .image-unit:hover .image-unit__prompt {
    opacity: 1;
  }
  
  /* 新增的模态框样式 */
  .image-unit__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .image-unit__modal-image {
    max-width: 90%;
    max-height: 90%;
    border-radius: 16px;
  }
  