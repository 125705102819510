/* 外层容器 */
.image-detail-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* 半透明背景 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .image-detail-card {
    width: 80%;
    padding: 20px;
    background-color: #353535;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: none;
    max-height: 90%;
    overflow: hidden;
  }
  
  .image-detail-header {
    display: flex;
  }
  
  .image-detail-main-img {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .image-detail-info {
    padding: 10px;
    color: aliceblue;
  }
  
  .image-detail-info h2 {
    margin-bottom: 16px;
  }
  
  .image-detail-close-btn {
    background-color: #ff4d4f;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 20px;
  }
  
  .image-detail-close-btn:hover {
    background-color: #ff7875;
  }
  
  .image-detail-close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 24px;
    color: #299bff;
    cursor: pointer;
    z-index: 1001; /* 确保关闭按钮位于卡片上方 */
  }
  
  .image-detail-close-icon:hover {
    color: #2c86f4;
  } 
  
  .image-detail-thumbnails {
    overflow: hidden;
    display: flex;
    padding-top: 10px;
  }
  
  .thumbnail-container {
    margin-right: 10px;
  }
  
  .thumbnail-img {
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }
  
  .thumbnail-img:hover {
  }
  
  /* 当图片全屏时的遮罩层 */
.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8); /* 全屏时较暗的背景 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
    cursor: zoom-out; /* 鼠标样式为缩小 */
}

/* 全屏的图片样式 */
.fullscreen-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 8px;
}

/* 全屏状态的关闭按钮 */
.fullscreen-close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 32px;
    color: #fff;
    cursor: pointer;
    z-index: 1101;
}

.fullscreen-close-icon:hover {
    color: #ff4d4f;
}

.feedback-buttons {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}


.raw-thumbnail {
  width: 50px; /* 小缩略图宽度 */
  height: auto;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 8px;
  transition: transform 0.2s ease-in-out;
}

.raw-thumbnail:hover {
  transform: scale(1.1); /* 鼠标悬停时放大 */
}
