/* 合并后的 CSS，调整了上下间距以使布局更紧凑 */

/* 模态框样式 */
.login-modal,
.register-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* 背景色 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* 容器样式 */
.login-container,
.register-container {
    display: flex;
    width: 800px;
    height: 500px;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* 阴影效果 */
}

/* 图片样式 */
.login-image,
.register-image {
    flex: 1;
    position: relative;
    height: 100%; /* 确保图片高度 */
}

.login-image img,
.register-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.register-image img {
    transition: opacity 0.5s ease; /* 渐变效果 */
}

/* 内容区域样式 */
.login-content,
.register-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px; /* 减小内边距 */
    text-align: center; /* 注册内容居中 */
    color: #333;
    background-color: #242628;
}

.login-content h2,
.register-content h2 {
    margin-bottom: 20px; /* 减小下边距 */
    font-size: 28px; /* 缩小字体大小 */
    color: rgb(255, 255, 255);
}

.login-content input,
.register-content input {
    margin: 5px 0; /* 上下间距 */
    padding: 12px; /* 内边距 */
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #444; /* 边框颜色 */
    border-radius: 5px; /* 圆角 */
    font-size: 14px; /* 字体大小 */
    background-color: #333; /* 深色背景 */
    color: white; /* 白色文字 */
    transition: border-color 0.3s, box-shadow 0.3s; /* 添加过渡效果 */
}

.login-content input:focus,
.register-content input:focus {
    border-color: #007bff; /* 聚焦时的边框颜色 */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* 聚焦时的阴影效果 */
    outline: none; /* 移除默认的聚焦样式 */
}

.login-content input::placeholder,
.register-content input::placeholder {
    color: #aaa; /* 占位符文字颜色 */
    font-style: italic; /* 添加斜体 */
    font-size: 13px; /* 调整字体大小 */
}



.button-group {
    margin-top: 10px;
}

.button-group button {
    margin: 5px;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.button-group button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.button-group button:active {
    background-color: #004085;
    transform: scale(0.95);
}

.button-group button:last-child {
    background-color: #6c757d;
}

.button-group button:last-child:hover {
    background-color: #5a6268;
}

/* 关闭按钮样式（仅用于登录） */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    z-index: 1001; /* 确保高于其他元素 */
}

/* 验证码组样式（仅用于注册） */
.code-group {
    display: flex;
    align-items: center;
    margin: 5px 0; /* 减小上下间距 */
}

.code-group button {
    padding: 8px 16px; /* 减小按钮内边距 */
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    margin-left: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    white-space: nowrap; /* 防止文字换行 */
    font-size: 14px; /* 缩小字体大小 */
}

.code-group button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.code-group button:hover:not(:disabled) {
    background-color: #0056b3;
}

.register-modal .agreement-section {
    margin-top: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px; /* 控制复选框和文字整体间距 */
    color: aliceblue;
}

.register-modal .agreement-section label {
    display: flex;
    align-items: center;
    gap: 5px; /* 复选框与文字的间距 */
    white-space: nowrap; /* 防止换行 */
}

.register-modal .agreement-checkbox {
    appearance: none; /* 去除默认样式 */
    width: 16px; /* 自定义宽度 */
    height: 16px; /* 自定义高度 */
    border: 1px solid #1890ff; /* 边框样式 */
    border-radius: 3px; /* 圆角样式 */
    background-color: #ffffff; /* 默认背景色 */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s, border-color 0.2s;
}

.register-modal .agreement-checkbox:checked {
    background-color: #1890ff; /* 选中状态背景色 */
    border-color: #1890ff; /* 选中状态边框色 */
}

.register-modal .agreement-checkbox:checked::before {
    content: "✔"; /* 添加选中符号 */
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
}

.register-modal .agreement-section-words {
    display: flex;
    align-items: center;
    gap: 5px; /* 控制文字与链接的间距 */
    color: aliceblue;
}

.register-modal .agreement-link {
    color: #1890ff;
    cursor: pointer;
}

.register-modal .agreement-link:hover {
    color: #40a9ff;
}

.agreement-page, .privacy-policy-page {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 600px;
}

.agreement-link {
    color: #1890ff;
    cursor: pointer;
    margin: 0 5px;
    text-decoration: underline;
}

.switch-register-type {
    display: flex;
    justify-content: center;
    margin-bottom: 20px; /* 调整按钮组与输入框的间距 */
    background-color: #333; /* 区域背景色 */
    border-radius: 5px; /* 圆角效果 */
    padding: 5px; /* 内边距 */
}

.switch-register-type button {
    flex: 1; /* 等宽 */
    padding: 10px 0;
    margin: 0 2px; /* 控制按钮间距 */
    border: none;
    border-radius: 3px;
    font-size: 14px; /* 字体大小 */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    background-color: #444; /* 默认背景色 */
    color: white; /* 默认字体色 */
}

.switch-register-type button.active {
    background-color: #007bff; /* 选中状态背景色 */
    color: white; /* 选中状态字体色 */
}

.switch-register-type button:hover {
    background-color: #0056b3; /* 悬停状态背景色 */
    color: white; /* 悬停状态字体色 */
}

.switch-register-type button:not(.active):hover {
    background-color: #555; /* 未选中时悬停的背景色 */
}

.switch-register-type {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* 按钮组阴影效果 */
}
