/* Transtyle.css */
.transtyle-container {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.transtyle-layout {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
}

.transtyle-split {
  display: flex;
  height: 100%;
}

.custom-sider {
  padding: 20px;
  width: 400px;
  background-color: rgba(0, 0, 0, 0.4);
}
.controls-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.style-selector-container,
.reversion-strength-container {
  position: relative;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  align-items: center;
  background-color: rgb(35, 35, 35);
  margin-bottom: 16px;
}

.style-title {
  position: absolute;
  top: 5px;
  left: 10px;
  font-size: 14px;
  color: white;
}

.style-buttons,
.reversion-strength-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.style-button,
.reversion-strength-button {
  margin: 10px 5px;
  flex: 1 1 22%;
  text-align: center;
  max-width: 100px;
}

.control-buttons {
  padding: 0 20px;
}

.generate-button {
  width: 100%;
}