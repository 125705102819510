/* src/assets/styles/SplitPane.css */
.gutter {
    background-color: #ddd;
    background-repeat: no-repeat;
    background-position: 50%;
  }
  
  .gutter.gutter-horizontal {
    cursor: col-resize;
  }
  
  .gutter.gutter-vertical {
    cursor: row-resize;
  }
  