/* Block: modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.modal__content {
    position: relative;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 1010;
}

.modal__title {
    margin-top: 0;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
}

/* Form styles */
.modal__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.modal__field {
    display: flex;
    flex-direction: column;
}

.modal__label {
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.modal__input {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.modal__input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

/* Modifier for inputs */
.modal__input--username {
    /* 可根据需求增加特定样式 */
}

.modal__input--email {
    /* 可根据需求增加特定样式 */
}

.modal__input--password {
    /* 可根据需求增加特定样式 */
}

/* Actions styles */
.modal__actions {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.modal__button {
    padding: 10px 15px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.modal__button--submit {
    background: #007bff;
    color: white;
}

.modal__button--submit:hover {
    background: #0056b3;
}

.modal__button--cancel {
    background: #6c757d;
    color: white;
}

.modal__button--cancel:hover {
    background: #5a6268;
}
