body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
}

.entrance-login-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: white;
  text-align: center;
  transition: transform 1s, opacity 1s;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}


.entrance-login-container.animate {
  transform: scale(1.2);
  opacity: 0;
}

.entrance-login-box {
  background: rgba(0, 0, 0, 0.5);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 600px;
  margin: 0 auto;
  transition: transform 1s, opacity 1s;
  z-index: 1; /* 确保内容在视频上面 */
}

.entrance-login-box h1 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 36px;
}

.entrance-login-box p {
  color: #ddd;
  margin-bottom: 40px;
}

.entrance-login-box button {
  padding: 15px 30px;
  background: #00aaff;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.entrance-login-box button:hover {
  background: #0088cc;
}
