.info-container {
    display: grid;
    grid-template-columns: 1fr 2fr; /* 左侧个人信息 1，右侧会员信息 2 */
    gap: 2rem;
    height: 100%;
    margin: 0 auto;
    border-radius: 8px;
    background-color: rgb(47, 47, 47);
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.info-personal-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.info-membership-section {
    background-color: #333;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    color: aliceblue;
}

.info-bind-text {
    color: #888; /* 更偏灰的颜色 */
    cursor: pointer;
    margin-left: 10px;
    text-decoration: underline;
}

.info-bind-text:hover {
    color: #555; /* 深灰色 */
}

.info-avatar-section {
    position: relative; /* 确保子元素可以相对于这个容器定位 */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px; /* 与头像相同 */
    height: 150px; /* 与头像相同 */
    margin: 0 auto;
}

.info-avatar-image {
    width: 100%; /* 适应父容器 */
    height: 100%; /* 适应父容器 */
    border-radius: 50%;
    border: 3px solid #eee;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    object-fit: cover;
}

.info-camera-overlay {
    position: absolute; /* 绝对定位 */
    top: 0;
    left: 0;
    width: 100%; /* 覆盖整个头像 */
    height: 100%; /* 覆盖整个头像 */
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s;
}

.info-avatar-section:hover .info-camera-overlay {
    opacity: 1;
}

.info-camera-icon {
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
}

.info-user-details {
    text-align: center;
}

.info-username {
    font-size: 2rem;
    font-weight: bold;
    color: aliceblue;
    margin: 0;
}

.info-email,
.info-phone {
    font-size: 1.2rem;
    color: #ccc;
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.info-label {
    margin-right: 5px;
    color: #ccc;
}

.info-membership-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: aliceblue;
    margin-bottom: 1rem;
    border-bottom: 2px solid #555;
    padding-bottom: 0.5rem;
}

.info-membership-info {
    font-size: 1rem;
    color: aliceblue;
    line-height: 1.6;
    margin-bottom: 1rem;
}

.info-no-membership {
    font-size: 1rem;
    color: #999;
}

.info-loading {
    text-align: center;
    font-size: 1.5rem;
    color: #666;
    padding: 2rem;
}

.info-bind-phone-inline {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.info-phone-input-inline,
.info-code-input-inline {
    padding: 0.4rem 0.6rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    width: 150px;
}

.info-send-code-button-inline,
.info-confirm-bind-button-inline,
.info-cancel-bind-button-inline {
    background: #bbb; /* 偏灰色 */
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
}

.info-send-code-button-inline:hover,
.info-confirm-bind-button-inline:hover,
.info-cancel-bind-button-inline:hover {
    background: #999; /* 更深的灰色 */
    transform: translateY(-1px);
}

.info-confirm-bind-button-inline {
    background: #bbb;
}

.info-cancel-bind-button-inline {
    background: #bbb;
}

@media (max-width: 768px) {
    .info-container {
        grid-template-columns: 1fr;
    }

    .info-bind-phone-inline {
        flex-direction: column;
        align-items: flex-start;
    }

    .info-phone-input-inline,
    .info-code-input-inline,
    .info-send-code-button-inline,
    .info-confirm-bind-button-inline,
    .info-cancel-bind-button-inline {
        width: 100%;
        max-width: none;
    }
}
