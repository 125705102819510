.organization-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 30px;
    background-color: #1c1c1c;
    min-height: 100vh;
    box-sizing: border-box;
}

.info-loading,
.no-organization p {
    color: #ffffff;
    font-size: 1.2rem;
    margin-top: 50px;
}

.no-organization {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.create-organization-button,
.add-member-button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 5px;
}

.create-organization-button {
    background-color: #4CAF50;
    color: #fff;
}

.create-organization-button:hover {
    background-color: #45a049;
}

.add-member-button {
    background-color: #007BFF;
    color: #fff;
}

.add-member-button:hover {
    background-color: #0056b3;
}

.organization-header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
}

.organization-actions {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
}

.custom-select {
    min-width: 200px;
    flex: 1;
}

.organization-info {
    width: 100%;
    background-color: #3a3a3a;
    border-radius: 10px;
    padding: 20px 30px;
    margin-bottom: 30px;
    color: #f1f1f1;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.organization-info h3 {
    margin-bottom: 20px;
    font-size: 1.8rem;
    border-bottom: 2px solid #555;
    padding-bottom: 10px;
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
}

.member-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.member-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.member-actions {
    display: flex;
    align-items: center;
    gap: 10px;
}

.edit-member-button,
.delete-member-button {
    padding: 6px 12px;
    font-size: 0.9rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.edit-member-button {
    background-color: #007BFF;
    color: #fff;
}

.edit-member-button:hover {
    background-color: #0056b3;
}

.delete-member-button {
    background-color: #e53935;
}

.delete-member-button:hover {
    background-color: #d32f2f;
}

.adding-member-item {
    background-color: #444;
}

.adding-member-item .member-details input {
    width: 100%;
    padding: 8px;
    border: 1px solid #555;
    border-radius: 4px;
    background-color: #3a3a3a;
    color: #e0e0e0;
}

.save-member-button,
.cancel-member-button {
    padding: 6px 12px;
    font-size: 0.9rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.save-member-button {
    background-color: #4CAF50;
    color: #fff;
}

.save-member-button:hover {
    background-color: #45a049;
}

.cancel-member-button {
    background-color: #888;
    color: #fff;
}

.cancel-member-button:hover {
    background-color: #666;
}
