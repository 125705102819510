/* quickToolsIndex.css */

.quick-tools__container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding : 3%;
    gap: 1%;
}

.quick-tools__item {
    width: 10%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 0px solid #ddd;
    border-radius: 8px;
    background-color: #242628;
    cursor: pointer;
    transition: transform 0.2s;
}

.quick-tools__item:hover {
    transform: translateY(-5px);
}

.quick-tools__image {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 4px;
}

.quick-tools__label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
    width: 100%;
    height: 100%;
    color: aliceblue;
    padding-left: 5%;
}

.quick-tools__description {
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    color: gray;
    text-align: center;
    width: 100%;
    height: 100%;
    padding-left: 5%;
}