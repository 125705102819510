/* 重置样式 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* 主题变量 */
:root {
  --navbar-width: 8%;
  --navbar-bg-color: #242628;
  --submenu-bg-color: #272727;
  --thirdmenu-bg-color: #2e2e2e;
  --text-color: #ffffff;
  --hover-bg-color: rgba(255, 255, 255, 0.1);
  --transition-duration: 0.3s;
  --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  --font-size: 16px;
}

.main-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-container {
  display: flex;
  height: calc(100vh - 5vh);
  flex: 1;
}

.content {
  flex: 1;
  background-color: #1c1c1c;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 95.5%;
}
