:root {
  --navbar-width: 10%;
  --navbar-bg-color: #242628;
  --submenu-bg-color: #2e2e2e;
  --thirdmenu-bg-color: #383838;
  --text-color: #ffffff;
  --hover-bg-color: rgba(255, 255, 255, 0.1);
  --transition-duration: 0.3s;
  --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  --font-size: 16px;
}

/* 导航栏样式 */
.navbar {
  width: var(--navbar-width);
  min-width: 70px;
  background-color: var(--navbar-bg-color);
  color: var(--text-color);
  font-family: var(--font-family);
  display: flex;
  font-size: var(--font-size);
  flex-direction: column;
  align-items: stretch; /* 改为 stretch 以填满宽度 */
  transition: width var(--transition-duration);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

/* 菜单样式 */
.main-menu,
.bottom-menu {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
}

.main-menu {
  flex-grow: 1; /* 主菜单占据剩余空间 */
}

.menu-item-wrapper {
  width: 100%;
}

.menu-item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start; /* 左对齐 */
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: background-color var(--transition-duration);
  padding: 16px; /* 增加上下内边距 */
}

.bottom-item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start; /* 左对齐 */
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: background-color var(--transition-duration);
}

.menu-item:hover,
.bottom-item:hover,
.submenu-item:hover{
  background-color: var(--hover-bg-color);
}


.third-menu-item:hover {
  background-color: var(--hover-bg-color);
}

.menu-item-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.menu-icon {
  margin-right: 8px;
}

.menu-arrow {
  margin-left: auto; /* 将箭头推到最右边 */
}

.submenu {
  list-style: none;
  width: 100%;
  background-color: var(--submenu-bg-color);
}

.submenu-item {
  cursor: pointer;
}

.submenu-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px;
  padding-left: 28px;
}

.submenu-arrow {
  margin-left: auto;
}

.third-menu {
  list-style: none;
  width: 100%;
  background-color: var(--thirdmenu-bg-color);
}

.third-menu-item {
  background-color: var(--navbar-bg-color);
  padding: 16px;
  padding-left: 36px;
  cursor: pointer;
}

/* 底部菜单样式 */
.bottom-menu {
  margin-top: auto;
}
