/* CustomInput.css */
.custom-box {
    max-height: 400px; /* 最大高度 */
    overflow-y: auto; /* 垂直滚动 */
    background-color: rgba(0, 0, 0, 0.7); /* 半透明背景 */
    color: white; /* 文本颜色 */
    border: 1px solid #000; /* 边框颜色 */
    padding: 10px; /* 内边距 */
    border-radius: 4px; /* 圆角 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 阴影 */
    z-index: 1000; /* 确保在其他元素之上 */
  }
  
.custom-input-wrapper .ant-input {
  width: 100%;
}

.custom-input-wrapper .ant-input-group-wrapper {
  display: flex;
  flex-direction: column;
}